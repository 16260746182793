import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import workoutReducer from '@/redux/slices/workout';
import checkInReducer from './slices/check-in';
import settingsReducer from './slices/settings';
import authReducer from './slices/auth';

const workoutPersistConfig = {
  key: 'workout',
  storage,
  blacklist: ['tracksLoaded'],
};
const settingsPersistConfig = {
  key: 'settings',
  storage,
  blacklist: ['screenMode'],
};
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['gym', 'lastUsedOrganizationId'],
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedWorkoutReducer = persistReducer(
  workoutPersistConfig,
  workoutReducer
);
const persistedSettingsReducer = persistReducer(
  settingsPersistConfig,
  settingsReducer
);

export const store = configureStore({
  reducer: {
    checkIn: checkInReducer,
    workout: persistedWorkoutReducer,
    settings: persistedSettingsReducer,
    auth: persistedAuthReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

'use client';

import { ReactNode } from 'react';
import { useInitApp } from '@/libs/utils/hooks';
import { checkTokenValidity } from '@/libs/utils/helpers';

export const InitAppProvider = ({ children }: { children: ReactNode }) => {
  checkTokenValidity();
  useInitApp();

  return children;
};
